<template>
  <v-container fluid>
    <v-row justify="start" align="center" no-gutters>
      <v-col cols="1">
        <v-switch
          v-model="lifecycle"
          color="primary"
          label="Drilling"
          value="1"
          @change="updatefiltre"
        ></v-switch>
      </v-col>
      <v-col cols="1">
        <v-switch
          ref="vOperating"
          v-model="lifecycle"
          color="primary"
          label="Operating"
          value="2"
          @change="updatefiltre"
        ></v-switch>
      </v-col>
      <v-col cols="1">
        <v-switch
          v-model="lifecycle"
          color="primary"
          label="P&A"
          value="3"
          @change="updatefiltre"
        ></v-switch>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <v-text-field
          v-if="viewlist == false"
          autocomplete="off"
          hide-details
          single-line
          solo-inverted
          clearable
          dense
          flat
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="pr-6"
        >
        </v-text-field> </v-col
      ><v-spacer />

      <v-col cols="12" sm="12" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'wellsstates'"
          :AllResponse="'allwellsstates'"
          :title="'Wells List'"
          :headers="headers"
          :field="'WELLNAME'"
          :field_list="['WELLNAME']"
          :filename="'Wells'"
          :sheetname="'Wells'"
          :showedit="false"
          @rowselect="WellChange"
          :chip_color="'red'"
          :Add="false"
          :del="false"
          :list_options="false"
          :key="klist"
          :search_elm="filtre"
          :load="loading"
          :CatScope="$store.state.fields"
          :FilterScope="wells_filter"
          :Type="lifecycle"
          :ipg="10"
        >
        </listitemspage>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <waiter :showWait="showWait"> </waiter>
  </v-container>
</template>

<script>
import WELLS from "../graphql/Well/WELLS.gql";
import WELL from "../graphql/Well/ALLWELL_STATE.gql";

function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),

    waiter: () => import("../components/Widgets/Waiter.vue"),
  },
  props: {},
  data: () => ({
    fields_cor_avg: [],
    flags_list: [],
    rapport_well: {},
    rapport_dialog: false,
    krapport: 100000,
    viewlist: true,
    showWait: false,
    filtre: "",
    search: "",
    expire: 1,
    selitem: {},
    loading: false,
    editer: false,
    valider: false,
    unfilter: false,
    list_key: false,
    pf: 0,
    kpa: 800,
    tool_list: [],
    defaults_list: [],

    headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        index: 0,
      },
      {
        text: "Compliant",
        value: "compliant_name",
        enum: "COMPLIANT_NAME",
        slot: "chip",
        color: "compliant_color",
        list: ["C", "NC"],
        selected: true,
        index: 1,
      },
      {
        text: "Name",
        align: "start",
        value: "wellname",
        enum: "WELLNAME",
        width: "100",
        selected: true,
        index: 2,
      },
      {
        text: "Date",
        value: "info_date",
        enum: "INFO_DATE",
        slot: "date",
        selected: true,
      },
      {
        text: "Surface Pressure (psi)",
        value: "surface_pressure",
        enum: "SURFACE_PRESSURE",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Bottomhole Pressure (psi)",
        value: "bottomhole_pressure",
        enum: "BOTTOMHOLE_PRESSURE",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Oil flow Rate (bpd)",
        value: "oilflow_rate",
        enum: "OILFLOW_RATE",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Gas Flow Rate (cf/d)",
        value: "gasflow_rate",
        enum: "GASFLOW_RATE",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Water Flow Rate (bwpd)",
        value: "waterflow_rate",
        enum: "WATERFLOW_RATE",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Surface Temp (F°)",
        value: "surface_temp",
        enum: "SURFACE_TEMP",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Bottom Hole Temp (F°)",
        value: "bottomhole_temp",
        enum: "BOTTOMHOLE_TEMP",
        slot: "cur",
        align: "end",
        selected: true,
      },

      {
        text: "Water Cut (%)",
        value: "water_cut",
        enum: "WATER_CUT",
        slot: "progress",

        selected: true,
      },
      {
        text: "Gas Oil Ratio (cf/bbl)",
        value: "gas_oil_ratio",
        enum: "GAS_OIL_RATIO",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Formation Pressure (psi)",
        value: "formation_pressure",
        enum: "FORMATION_PRESSURE",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Pressure Gradient (psi/ft)",
        value: "pressure_gradient",
        enum: "PRESSURE_GRADIENT",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    headers_old: [
      {
        text: "No",
        value: "no",
        selected: true,
        index: 0,
      },
      {
        text: "Compliant",
        value: "compliant_name",
        enum: "COMPLIANT_NAME",
        slot: "chip",
        color: "compliant_color",
        list: ["C", "NC"],
        selected: true,
        index: 1,
      },
      {
        text: "Name",
        align: "start",
        value: "wellname",
        enum: "WELLNAME",
        slot: "href",
        width: "100",
        selected: true,
        index: 2,
      },

      {
        text: "Life Cycle",
        value: "lifecyclename",
        enum: "LIFECYCLENAME",
        list: ["Drilling", "Operating", "P&A"],
        sortable: false,
        align: "left",
        selected: true,
        index: 3,
      },
      {
        text: "Drilling Date",
        value: "drillingdate",
        enum: "DRILLINGDATE",
        slot: "date",
        width: "120",
        selected: false,
        index: 4,
      },
      {
        text: "Integrety",
        value: "integrity_status_name",
        enum: "INTEGRITY_STATUS_NAME",
        list: [
          "Unknown",
          "Safe to Operate",
          "Safe to Operate Conditional",
          "Not Safe to Operate",
        ],
        slot: "chip",
        color: "integrity_status_color",
        selected: true,
        index: 5,
      },
      {
        text: "Status",
        value: "wellstatus",
        list: [],
        enum: "WELLSTATUS",
        selected: true,
        index: 6,
      },
      {
        text: "Anomaly",
        value: "anomaly",
        enum: "ANOMALY",
        slot: "chip",
        selected: true,
        index: 7,
      },
      {
        text: "Field",
        value: "fieldname",
        enum: "FIELDNAME",
        list: [],
        selected: true,
        index: 8,
      },
      {
        text: "Sector",
        value: "region",
        enum: "REGION",
        list: [],
        selected: true,
        index: 9,
      },

      {
        text: "Well Type",
        value: "welltype",
        enum: "WELLTYPE",
        list: [],
        selected: true,
        index: 10,
      },
      {
        text: "Closed Due Date",
        value: "gored",
        enum: "GORED",
        slot: "date",
        width: "120",
        selected: true,
        index: 11,
      },
      {
        text: "Action Due",
        value: "goreddays",
        selected: true,
        sortable: false,
        index: 12,
      },
      {
        text: "Deferal",
        value: "w",
        enum: "W",
        selected: true,
        index: 13,
      },

      {
        text: "Status",
        value: "status_name",
        enum: "STATUS_NAME",
        list: ["Pending", "Valid"],
        selected: true,
        index: 14,
      },
      {
        text: "Attachments",
        value: "nbdocs",
        enum: "NBDOCS",
        slot: "col_btn3",
        selected: false,
        icon: "mdi-attachment",
        tooltip: "Attached Documents",
        sortable: false,
        width: "80",
        index: 15,
      },
      {
        text: "# Casing",
        value: "nb_casing",
        enum: "NB_CASING",
        selected: false,
        index: 16,
      },
      {
        text: "# Formations",
        value: "nb_formations",
        enum: "NB_FORMATIONS",
        selected: false,
        index: 17,
      },
      {
        text: "Repport",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-view-list",
        width: "10",
        valeur: 0,
        sortable: false,
        index: 18,
      },
    ],
    showMenu: false,
    selectedItem: 0,
    x: 0,
    y: 0,
    Qselect: {},
    Qselectall: {},
    Qdetaildelete: {},
    isWellClosed: true,
    isFilterClosed: true,
    well: {},
    klist: 0,
    tf: 1000,
    kd: 100,
    fl: 200,
    title: "",
    list: [],
    listheaders: [],
    wells_filter: null,
    cements_filter: null,
    corrosions_filter: null,
    components_filter: [],
    compliants_filter: [],
    cor_where_list: [],
    doc: {},

    lifecycle: ["2"],

    users: [],
    new_item_code: "",
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    vsizes_list: [],
    tab2: null,
  }),

  computed: {},
  watch: {
    search: debounce(function () {
      this.expire = this.search ? this.expire3 : this.expire1;
    }, 1000),
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
    "$store.state.fields": {
      handler() {
        this.klist++;
      },
    },
  },

  async created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.Qselect = WELLS;
    this.Qselectall = WELL;
  },

  methods: {
    WellChange(item) {
      if (item) this.well = item;
      this.selitem = item;
      this.tf++;
    },

    OpenFilterForm() {
      this.isFilterClosed = false;
    },
    FilterList(components_filter, compliants_filter, filter) {
      this.wells_filter = JSON.stringify(filter);
      this.compliants_filter = compliants_filter;
      this.components_filter = components_filter;

      this.unfilter = true;
      this.klist++;
      this.isFilterClosed = true;
    },
    closeFilterForm() {
      this.isFilterClosed = true;
    },

    unfiltrer() {
      this.unfilter = false;
      this.corrosions_filter = null;
      this.cements_filter = null;
      this.wells_filter = null;
      this.$store.dispatch("Changed", true);
    },
    async changeview() {
      this.viewlist = !this.viewlist;
    },
    updatefiltre() {
      this.klist++;
      this.list_key = !this.list_key;
    },
  },
};
</script>
